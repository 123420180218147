const degrees = [
    {
        school: 'Trinity International University',
        degree: 'M.A. Counseling Psychology',
        link: 'https://tiu.edu',
        year: 1988,
    },
    {
        school: 'Trinity International University',
        degree: 'B.A. Psychology',
        link: 'https://tiu.edu',
        year: 1986,
    },
];

export default degrees;